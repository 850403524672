import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <a
                  href="https://wa.me/13605362634?text=I%27m%20interested%20in%20your%20mulch%20service%20%24220%20special%2A"
                  style={{ justifyContent: "center", display: 'flex', marginBottom: '10px'}}
                >
                  <img src="img/WhatsAppButtonGreenSmall.svg" className="img-responsive" alt="" />{" "}
                </a>{" "}
                <a
                  href="tel:3605362634"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Call Us
                </a>{" "}
                <a
                  href="sms:3605362634"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Text Us
                </a>{" "}

              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
